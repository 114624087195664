/*[eslint-disable-next-line no-unused-vars] */
/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import { useSelectedUser } from "./components/staff/SelectedUserProvider";

//sow patient review
const PatientReview = React.lazy(() => import("./components/patients/PatientReview"));

const PatientReferral = React.lazy(() => import("./components/patients/PatientReferral"));
const PatientReferralDetails = React.lazy(() => import("./components/patients/PatientReferralDetails"));

const Staff = React.lazy(() => import("./components/staff/Staff"));
const LabRequested = React.lazy(() => import("./components/labs/LabRequested"));
const LabSample = React.lazy(() => import("./components/labs/LabSample"));
const LabRequestedResult = React.lazy(() => import("./components/labs/LabRequestedResult"));
const LabRequestOrderDetails = React.lazy(() => import("./components/labs/LabRequestOrderDetails"));
const Login = React.lazy(() => import("./components/pages/login"));
const ForgotPassword = React.lazy(() => import("./components/pages/login/ForgotPassword"));
const Signup = React.lazy(() => import("./components/pages/login/Signup"));
const Register = React.lazy(() => import("./components/pages/login/Register"));
const LockScreen = React.lazy(() => import("./components/pages/login/LockScreen"));
const ChangePassword = React.lazy(() => import("./components/pages/login/ChangePassword"));
const Profile = React.lazy(() => import("./components/pages/login/Profile"));
const EditProfile = React.lazy(() => import("./components/pages/login/EditProfile"));
const ResetPassword = React.lazy(() => import("./components/pages/login/ResetPassword"));

const SearchResult = React.lazy(() => import("./components/search/SearchResult"));

const Setting = React.lazy(() => import("./components/settings/Setting"));
const AuditTrail = React.lazy(() => import("./components/settings/AuditTrail"));
const Diagnosis = React.lazy(() => import("./components/settings/Diagnosis"));
const Branch = React.lazy(() => import("./components/branches/Branch"));
const ReviewCheck = React.lazy(() => import("./components/review/ReviewCheck"));

const AppoinmentList = React.lazy(() => import("./components/appoinments/AppoinmentList"));
const EditAppoinments = React.lazy(() => import("./components/appoinments/EditAppoinments"));
const Flowboard = React.lazy(() => import("./components/flowboard/Flowboard"));
const PatientsList = React.lazy(() => import("./components/patients/PatientsList"));
const AddPatients = React.lazy(() => import("./components/patients/AddPatients"));
const EditPatients = React.lazy(() => import("./components/patients/EditPatients"));
const PatientsProfile = React.lazy(() => import("./components/patients/PatientsProfile"));
const PatientAllergyList = React.lazy(() => import("./components/patients/PatientAllergyList"));
const PatientVital = React.lazy(() => import("./components/patients/PatientVitals"));
const SystemReview = React.lazy(() => import("./components/review/SystemReview"));
const TreatmentPlan = React.lazy(() => import("./components/treatment-plan/TreatmentPlan"));
const PatientReferal = React.lazy(() => import("./components/referal/PatientReferal"));
const Consultation = React.lazy(() => import("./components/consultation/Consultation"));
const AddConsultation = React.lazy(() => import("./components/consultation/AddConsultation"));
const Prescription = React.lazy(() => import("./components/prescription/Prescription"));
const AddPrescription = React.lazy(() => import("./components/prescription/AddPrescription"));
const PrescriptionEdit = React.lazy(() => import("./components/prescription/PrescriptionEdit"));

const Pharmacy = React.lazy(() => import("./components/prescription/Pharmacy"));

const Labs = React.lazy(() => import("./components/labs/Labs"));
const LabRequest = React.lazy(() => import("./components/labs/LabRequest"));
const LabRequestDetails = React.lazy(() => import("./components/labs/LabRequestDetails"));
const Doctor_Dashboard = React.lazy(() => import("./components/Dashboard/Doctor_Dashboard/Doctor_Dashboard"));
const Admin_Dashboard = React.lazy(() => import("./components/Dashboard/Admin_Dashboard/Admin_Dashboard"));
const Patient_Dashboard = React.lazy(() => import("./components/Dashboard/Patient_Dashboard/Patient_Dashboard"));

const Calender = React.lazy(() => import("./components/calender/Calender"));

const Items = React.lazy(() => import("./components/items/Items"));
const Procedures = React.lazy(() => import("./components/items/Procedures"));
const Services = React.lazy(() => import("./components/items/Services"));
const Inventory = React.lazy(() => import("./components/items/Inventory"));
const Prices = React.lazy(() => import("./components/financial/Prices"));

const StaffList = React.lazy(() => import("./components/staff/StaffList"));
const AddStaff = React.lazy(() => import("./components/staff/AddStaff"));
const EditStaff = React.lazy(() => import("./components/staff/EditStaff"));
const StaffProfile = React.lazy(() => import("./components/staff/StaffProfile"));

const AccessControl = React.lazy(() => import("./components/access-control/AccessControl"));
const BranchHistoryInvoices = React.lazy(() => import("./components/accounts/BranchHistoryInvoices"));
const OverDueInvoice = React.lazy(() => import("./components/Invoice/Invoice-List/Overdue-Invoice/OverDue"));
const InvoiceList = React.lazy(() => import("./components/Invoice/Invoice-List/InvoiceList"));
const PaidInvoice = React.lazy(() => import("./components/Invoice/Invoice-List/Paid-Invoice/Paid_Invoice"));
const DraftInvoice = React.lazy(() => import("./components/Invoice/Invoice-List/Draft_Invoice/Draft_Invoice"));
const RecurringInvoice = React.lazy(() => import("./components/Invoice/Invoice-List/Recurring_Invoice/Recurring_Invoice"));
const CancelledInvoice = React.lazy(() => import("./components/Invoice/Invoice-List/Cancelled_Invoice/Cancelled_Invoice"));
const InvoiceGrid = React.lazy(() => import("./components/Invoice/Invoices_Grid/Invoice_Grid"));
const AddInvoice = React.lazy(() => import("./components/Add_Invoices/AddInvoice"));

// const AddInvoice = React.lazy(() => import("./components/Invoice/Add_Invoices/AddInvoices"));
const EditInvoices = React.lazy(() => import("./components/Add_Invoices/EditInvoice"));
const InvoiceDetails = React.lazy(() => import("./components/Invoice/Invoice_Details/Invoice_Details"));
const PatientVisitInvoice = React.lazy(()=> import ("./components/accounts/PatientVisitInvoice"));
const PatientHistoryInvoices = React.lazy(()=> import ("./components/accounts/PatientHistoryInvoices"));
const Payments = React.lazy(()=> import ("./components/accounts/Payments"));
const AddPayment = React.lazy(()=> import ("./components/accounts/Add_Payment"));
const PatientReport = React.lazy(() => import("./components/reports/patients/PatientReport"));
const ProcedureReport = React.lazy(() => import("./components/reports/procedures/ProcedureReport"));
const FinancialReport = React.lazy(() => import("./components/reports/financial/FinancialReport"));
const InventoryReport = React.lazy(() => import("./components/reports/inventory/InventoryReport"));
const InsuranceReport = React.lazy(() => import("./components/reports/insurance/InsuranceReport"));
const ServiceReport = React.lazy(() => import("./components/reports/services/ServiceReport"));
const BlankForms = React.lazy(() => import("./components/reports/blank-forms/BlankForms"));
const ClinicReport = React.lazy(() => import("./components/reports/clinic/ClinicReport"));
const VisitReport = React.lazy(() => import("./components/reports/visits/VisitReport"));
const PaymentReceipt = React.lazy(()=> import ("./components/Payroll/PaymentReceipt"));
const AutomaticInvoice = React.lazy(()=> import ("./components/Add_Invoices/AutomaticInvoice"));

const Onboarding = React.lazy(() => import("./components/onboarding/Onboarding"));
const BCSetup = React.lazy(() => import("./components/onboarding/BCSetup"));
const APISetup = React.lazy(() => import("./components/onboarding/APISetup"));
const InsuranceCompany = React.lazy(() => import("./components/insurance-company/InsuranceCompany"));

//Accounts
const Approuter = () => {

    const { menuItem } = useSelectedUser();

    const pathnames = useLocation().pathname.split('/');
    const [pathname, setPathname] = useState(pathnames[1] || '');

    useEffect(() => {

        setPathname(pathnames[1] || '');
    }, [pathnames]);

    return (
        <>
            <Header />
            <Sidebar id={`menu-item${menuItem}`} id1={`menu-items${menuItem}`} activeClassName={pathname} />
            <div className="page-wrapper">
                <Suspense fallback={<div className="loading"></div>}>
                    <Routes>
                        <Route path="/" element={<Admin_Dashboard />} />
                        <Route path="/forgotpassword" element={<ForgotPassword />} />
                        <Route path="/signup" element={<Signup />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/edit-profile" element={<EditProfile />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/lockscreen" element={<LockScreen />} />
                        <Route path="/changepassword" element={<ChangePassword />} />
                        <Route path="/resetLink" element={<ResetPassword />} />

                        {/* Invoices and Payment */}
                       
                        <Route path="/patient-visit-invoice" element={<PatientVisitInvoice />} />
                        <Route path="/patient-invoices" element={<PatientHistoryInvoices />} />
                        <Route path="/branch-invoices" element={<BranchHistoryInvoices />} />
                        <Route path="/createinvoice" element={<AddInvoice />} />
                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/paid-invoice" element={<PaidInvoice />} />
                        <Route path="/overdue-invoice" element={<OverDueInvoice />} />
                        <Route path="/draft-invoice" element={<DraftInvoice />} />
                        <Route path="/recurring-invoice" element={<RecurringInvoice />} />
                        <Route path="/cancelled-invoice" element={<CancelledInvoice />} />
                        <Route path="/invoice-grid" element={<InvoiceGrid />} />
                        <Route path="/create-invoice" element={<AddInvoice />} />
                       
                        <Route path="/invoice-details" element={<InvoiceDetails />} />

                        {/* Search Result  */}
                        <Route path="/search-result" element={<SearchResult />} />

                        {/* Patients  */}
                        <Route path="/patientslist" element={<PatientsList />} />
                        <Route path="/addpatients" element={<AddPatients />} />
                        <Route path="/editpatients" element={<EditPatients />} />
                        <Route path="/patientsprofile" element={<PatientsProfile />} />
                        <Route path="/appoinmentlist" element={<AppoinmentList />} />
                        <Route path="/editappoinments" element={<EditAppoinments />} />
                        <Route path="/flowboard" element={<Flowboard />} />
                        <Route path="/consultation" element={<Consultation />} />
                        <Route path="/edit-prescription" element={<PrescriptionEdit />} />
                        <Route path="/addconsultation" element={<AddConsultation />} />
                        <Route path="/treatmentplan" element={<TreatmentPlan />} />
                        <Route path="/treatmentplan" element={<TreatmentPlan />} />
                        <Route path="/patientreferal" element={<PatientReferal />} />
                        <Route path="/systemreview" element={<SystemReview />} />
                        <Route path="/patient-vital" element={<PatientVital />} />
                        <Route path="/allergy" element={<PatientAllergyList />} />
                        <Route path="/prescription" element={<Prescription />} />
                        <Route path="/prescription-add" element={<AddPrescription />} />
                        <Route path="/lab-request" element={<LabRequest />} />
                        <Route path="/lab-request-details" element={<LabRequestDetails />} />

                        {/* Invoices and Payment */}
                        <Route path="/invoicebills" element={<BranchHistoryInvoices />} />
                        {/* <Route path="/createinvoice" element={<AddInvoice />} /> */}
                        <Route path="/invoice-list" element={<InvoiceList />} />
                        <Route path="/paid-invoice" element={<PaidInvoice />} />
                        <Route path="/overdue-invoice" element={<OverDueInvoice />} />
                        <Route path="/draft-invoice" element={<DraftInvoice />} />
                        <Route path="/recurring-invoice" element={<RecurringInvoice />} />
                        <Route path="/cancelled-invoice" element={<CancelledInvoice />} />
                        <Route path="/invoice-grid" element={<InvoiceGrid />} />
                        <Route path="/add-invoice" element={<AddInvoice />} />
                        <Route path="/invoice-auto" element={<AutomaticInvoice />} />
                    
                        {/* <Route path="/add-invoice" element={<AddInvoice />} /> */}
                        <Route path="/create-invoice" element={<AddInvoice />} />
                        <Route path="/edit-invoice" element={<EditInvoices />} />
                        <Route path="/invoice-details" element={<InvoiceDetails />} />
                        <Route path="/patient-visit-invoice" element={<PatientVisitInvoice />} />

                        <Route path="/payment" element={<Payments />} />
                        <Route path="/addpayment/:id" element={<AddPayment />} />
                        <Route path="/payment/receipt" element={<PaymentReceipt />} />
                        {/* Invoices and Payment */}

                        <Route path="/pharmacy/*" element={<Pharmacy />} />

                        {/* Laboratory  */}
                        <Route path="/labs/*" element={<Labs />} />
                        <Route path="/lab-requested" element={<LabRequested />} />
                        <Route path="/lab-sample" element={<LabSample />} />
                        <Route path="/lab-request-order" element={<LabRequestOrderDetails />} />
                        <Route path="/lab-result" element={<LabRequestedResult />} />

                        {/* Items and services */}
                        <Route path="/items/*" element={<Items />} />
                        <Route path="/prices/*" element={<Prices />} />
                        <Route path="/procedure/*" element={<Procedures />} />
                        <Route path="/service/*" element={<Services />} />
                        <Route path="/inventory/*" element={<Inventory />} />

                        {/* Settings */}
                        <Route path="/settings/*" element={<Setting />} />
                        <Route path="/audittrail" element={<AuditTrail />} />
                        <Route path="/diagnosis/*" element={<Diagnosis />} />
                        <Route path="/branch/*" element={<Branch />} />
                        <Route path="/reviewcheck/*" element={<ReviewCheck />} />

                        {/* Calender */}
                        <Route path="/calender" element={<Calender />} />

                        <Route path="/patient-review" element={<PatientReview />} />

                        <Route path="/patient-referral" element={<PatientReferral />} />
                        <Route path="/referral-details" element={<PatientReferralDetails />} />

                        {/* Staff */}
                        <Route path="/staff/*" element={<Staff />} />

                        {/* Access Control */}
                        <Route path="/accesscontrol/*" element={<AccessControl />} />

                        {/* Dashboard */}
                        <Route path="/admin-dashboard" element={<Admin_Dashboard />} />
                        <Route path="/doctor-dashboard" element={<Doctor_Dashboard />} />
                        <Route path="/patient-dashboard/*" element={<Patient_Dashboard />} />

                        {/* Onboarding */}
                        <Route path="/patient-report/*" element={<PatientReport />} />
                        <Route path="/procedure-report/*" element={<ProcedureReport />} />
                        <Route path="/clinic-report/*" element={<ClinicReport />} />
                        <Route path="/visit-report/*" element={<VisitReport />} />
                        <Route path="/financial-report/*" element={<FinancialReport />} />
                        <Route path="/inventory-report/*" element={<InventoryReport />} />
                        <Route path="/insurance-report/*" element={<InsuranceReport />} />
                        <Route path="/service-report/*" element={<ServiceReport />} />
                        <Route path="/blank-forms/*" element={<BlankForms />} />

                        {/* Onboarding */}
                        <Route path="/onboarding/*" element={<Onboarding />} />
                        <Route path="/bcsetup/*" element={<BCSetup />} />
                        <Route path="/apisetup/*" element={<APISetup />} />
                        <Route path="/insurancelist/*" element={<InsuranceCompany />} />

                    </Routes>
                </Suspense>
            </div>
        </>
    );
}

export default Approuter;
